import { ComponentProps, FC } from "react";
import { Formik } from "formik";
import * as _ from "lodash";
import { withProps } from "hoc-with-props";
import moment from "moment";

import {
  CtraLayout,
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  DeleteOutlined,
  Space,
  Button,
  AntDesignForm,
  Table
} from "@ctra/components";

import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { FarmInputDataset, FarmInputDatasetField, FarmInputValue } from "@ctra/api";
import { TS } from "@ctra/utils";

import { useFarmInputs } from "@settings";
import { useFarm } from "@farms";

type SortOrder = ComponentProps<typeof Table.Column>["defaultSortOrder"];

const {
  settings: {
    inputs: {
      widget: { title, labels }
    }
  }
} = Content;

const { ContentWrapper } = CtraLayout;

/**
 * Get the form component based on the field type
 * @type {(({name, dataType, values, boundaries}: FarmInputDatasetField) => React.FC<any>) & MemoizedFunction}
 */
const getFormComponent = _.memoize(
  // eslint-disable-next-line
  ({ name, dataType, values, boundaries, decimals }: FarmInputDatasetField): FC<any> => {
    switch (dataType) {
      case "date": {
        return withProps({
          disabledDate: (value: moment.Moment) => value.isAfter(moment().endOf("day")),
          allowClear: false,
          name,
          format: (m: moment.Moment) => m.format("L")
        })(DatePicker);
      }
      case "dateTime": {
        return withProps({
          disabledDate: (value: moment.Moment) => value.isAfter(moment().endOf("day")),
          showTime: { format: "HH:mm" },
          name,
          format: (m: moment.Moment) => m.format("L")
        })(DatePicker);
      }
      case "float": {
        // eslint-disable-next-line
        const [min, max] = boundaries!;

        return withProps({
          name,
          min,
          max,
          precision: decimals,
          step: decimals ? 1 / Math.pow(10, decimals) : 0.1
        })(InputNumber);
      }
      case "currency": {
        return withProps({
          name,
          options: values?.map((value) => ({
            value,
            label: value
          }))
        })(Select);
      }
      default: {
        return withProps({ name })(Input);
      }
    }
  }
);

/**
 * Price input widget
 * @param {string} datasetName
 * @returns {JSX.Element}
 * @constructor
 */
export const PriceInputWidget: FC<{ datasetName: string }> = ({ datasetName }) => {
  const { t } = useTranslation();
  const { farm } = useFarm();

  const {
    api: { adjust, delete: deleteValue },
    meta: { dataSets, isLoading, isUpdating, isDeleting },
    values
  } = useFarmInputs();

  /**
   * This can be an assumed type as we know the data structure
   */
  const { fields, dedupKey } = _.find(dataSets, ["name", datasetName]) as FarmInputDataset;

  return (
    <ContentWrapper isLoading={isLoading} title={t<string>(title(datasetName))}>
      <Row gutter={16} wrap={false}>
        <Col flex={1}></Col>
        <Col>
          <Formik<FarmInputValue>
            isInitialValid={false}
            validate={(inputValues) => {
              const [primaryKey] = dedupKey;
              const { dataType } = _.find(fields, { name: primaryKey }) as FarmInputDatasetField;
              const primaryValue = inputValues[primaryKey] as string;
              const list = values[datasetName].values;
              const errors: Partial<Record<FarmInputDatasetField["name"], string>> = {};

              switch (dataType) {
                case "date":
                  const match = _.find(list, (item) =>
                    TS.asMoment(item[primaryKey] as string).isSame(TS.asMoment(primaryValue), "day")
                  );

                  if (match) {
                    errors[primaryKey] = "A value for this date is already set.";
                  }

                  return errors;
                case "dateTime": {
                  const match = _.find(list, (item) =>
                    TS.asMoment(item[primaryKey] as string).isSame(TS.asMoment(primaryValue), "hour")
                  );

                  if (match) {
                    errors[primaryKey] = "A value for this date is already set.";
                  }

                  return errors;
                }
              }

              return errors;
            }}
            initialValues={_.reduce(
              fields,
              (acc, { name, dataType, values }) => {
                if (dataType === "dateTime") {
                  acc[name] = TS.now();
                } else if (dataType === "float") {
                  acc[name] = 0;
                } else if (values) {
                  acc[name] = values[0];
                }

                return acc;
              },
              {} as Record<FarmInputDatasetField["name"], string | number>
            )}
            onSubmit={(values) => {
              if (farm) {
                adjust(
                  farm.id,
                  datasetName,
                  _.mapValues(values, (value) => {
                    if (_.isString(value)) {
                      const asMoment = TS.asMoment(value);
                      const isValid = asMoment.isValid();

                      if (isValid) {
                        return TS.serialize(asMoment.startOf("day"));
                      }

                      return value;
                    }

                    return value;
                  })
                );
              } else {
                throw new Error("You cannot use this without a farm.");
              }
            }}
          >
            {({ isValid }) => {
              return (
                <Form layout="vertical">
                  <Space size="middle">
                    {/* Currencies are considered addons to the previous field */}
                    {_.map(fields, (field, idx, collection) => {
                      const Component = getFormComponent(field);
                      const nextField = collection[idx + 1];
                      const NextComponent = nextField ? getFormComponent(nextField) : null;

                      return field.dataType === "currency" ? null : (
                        <Form.Item
                          key={field.name}
                          name={field.name}
                          label={t<string>(labels.formField(field.name))}
                        >
                          <Component
                            required
                            disabled={isUpdating}
                            addonAfter={
                              nextField.dataType === "currency" && NextComponent ? <NextComponent /> : void 0
                            }
                          />
                        </Form.Item>
                      );
                    })}
                    <AntDesignForm.Item label=" ">
                      <Button
                        loading={isUpdating}
                        disabled={!isValid || isUpdating}
                        type="primary"
                        htmlType="submit"
                      >
                        {t<string>(labels.submit)}
                      </Button>
                    </AntDesignForm.Item>
                  </Space>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Table<FarmInputValue>
            loading={isUpdating || isDeleting.includes(datasetName)}
            dataSource={_.get(values, [datasetName, "values"], [])}
            columns={[
              ..._.compact(
                _.map(fields, (field, idx, collection) => {
                  const basics: Record<string, unknown> = {
                    title: t<string>(labels.formField(field.name)),
                    dataIndex: field.name,
                    key: field.name
                  };

                  switch (field.dataType) {
                    case "currency": {
                      return null;
                    }
                    case "date":
                    case "dateTime": {
                      return {
                        ...basics,
                        defaultSortOrder: "descend" as SortOrder,
                        sorter: (a: any, b: any) => {
                          return TS.asMoment(a.startTs).isAfter(TS.asMoment(b.startTs)) ? 1 : -1;
                        },
                        render: (value: string) => {
                          return TS.asMoment(value).format(field.dataType === "date" ? "L" : "LL");
                        }
                      };
                    }
                    default: {
                      return {
                        ...basics,
                        render: (value: string | number, record: FarmInputValue) => {
                          const nextField = collection[idx + 1];
                          const { decimals } = field;

                          return nextField && nextField.dataType === "currency"
                            ? `${Number(value).toFixed(decimals)} ${record[nextField.name]}`
                            : value;
                        }
                      };
                    }
                  }
                })
              ),
              {
                title: t<string>(labels.action),
                key: "action",
                align: "right",
                render: (value, record) => (
                  <Space>
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        if (farm) {
                          deleteValue(farm.id, datasetName, record);
                        }
                      }}
                    />
                  </Space>
                )
              }
            ]}
          />
        </Col>
      </Row>
    </ContentWrapper>
  );
};

import { Epoch, memoize } from "@ctra/utils";
import * as _ from "lodash";

import { BulbCircle } from "@ctra/components";

import { StockingDensityInsightBody } from "@insights";
import { GenericInsightBase } from "./Base";
import { OnboardingInsightValidation } from "@insights";

class StockingDensityInsight extends GenericInsightBase {
  @memoize
  getComponents(): Omit<ReturnType<typeof GenericInsightBase.prototype.getComponents>, "Body"> & {
    Body: typeof StockingDensityInsightBody;
  } {
    return {
      ...super.getComponents(),
      Body: StockingDensityInsightBody,
      Validation: OnboardingInsightValidation, // same component basically
      Icon: BulbCircle,
      ListIcon: BulbCircle
    };
  }

  @memoize
  getMetadata(): {
    cowsHigh: number;
    cowsLow: number;
    datapointsD: string[];
    datapointsX: number[];
    datapointsY: number[];
    penName: string;
    drop: number;
    metricName: "feedEfficiency" | "averageDMI" | "averageYield";
    startDate: string;
    endDate: string;
    curveSlope: number;
    curveMidpoint: number;
    curveHigh: number;
  } {
    const meta = super.getMetadata();
    const { startEpoch, endEpoch } = this.entity;
    const startDate = Epoch.asMoment(Epoch.load(startEpoch)).format("LL");
    const endDate = Epoch.asMoment(Epoch.load(endEpoch)).format("LL");
    const { cowsLow, cowsHigh } = meta;

    return {
      ..._.pick(
        _.mapValues(meta, (v) => (_.isNumber(v) ? _.round(v, 2) : v)),
        "curveSlope",
        "curveMidpoint",
        "curveHigh",
        "datapointsD",
        "datapointsX",
        "datapointsY",
        "penName",
        "drop",
        "metricName"
      ),
      cowsLow: _.round(cowsLow),
      cowsHigh: _.round(cowsHigh),
      startDate,
      endDate
    };
  }
}

export { StockingDensityInsight };

import { Nullable, UnitSystem } from "@ctra/utils";

import { DataDescriptorEntity } from "../data-dictionary";
import { FarmEntity } from "../farms";
import { UserEntity } from "../user";
import { HerdGroupEntity } from "../herd-groups";
import { DairyCowEntity, DairyCowSource } from "../cows";
import { UnitSystemParam } from "../chart";

export enum InsightScope {
  cow = "cow",
  herd = "herd"
}

export enum InsightType {
  stockingDensity = "StockingDensity",
  farmKPI = "KPIInsightFarm",
  lelyMilkDrop = "LelyMilkDrop",
  lelyNoMilkDrop = "LelyNoMilkDrop",
  lelyKetosis = "LelyKetosis",
  ajinomotoBloodTest = "CowsToBloodScreen",
  ajinomotoIndividualBloodTest = "CowToBloodScreen",
  ajinomotoFeedingSchedule = "AdditiveRecommendation",
  ajinomotoNutritionRecommendation = "NutritionRecommendation",
  ajinomotoHighRiskCows = "HighRiskCows",
  ajinomotoHighRiskCow = "HighRiskCow",
  onboarding = "Onboarding",
  vulcanV2 = "VulcanV2"
}

export enum InsightState {
  live = "Live",
  hold = "Hold",
  forcedLive = "ForcedLive"
}

export enum InsightCategory {
  fertility = "fertility",
  health = "health"
}

export enum InsightValidation {
  na = "NA",
  yes = "Yes",
  no = "No",
  maybe = "Maybe"
}

export enum ValidationLookup {
  NA = "na",
  Yes = "yes",
  No = "no",
  Maybe = "maybe"
}

/**
 * @todo move it to a more generic spot
 */
export enum NotificationType {
  email = "email",
  push = "push"
}

export enum NotificationAction {
  enable = "enable",
  disable = "disable"
}

export type RequestActionMap = {
  [index in NotificationAction]: string;
};

export interface InsightTypeSource {
  InsightTypeID: number;
  createdEpoch: number;
  insightCategory: InsightCategory;
  insightCategorySpecific: string;
  insightScope: InsightScope;
  lowerBoundInterval: number;
  modifiedEpoch: number;
  slidingWindowInterval: number;
  title: string;
  typeName: string;
  upperBoundInterval: number;
}

export interface InsightValidationSource {
  InsightValidationID: number;
  genericInsightID: GenericInsightEntity["id"];
  validation: InsightValidation;
  validationByID: UserEntity["id"];
  validationEpoch: number;
}

export enum InsightWorkflowState {
  toCheck = "toCheck",
  toFollowUp = "toFollowUp",
  done = "done"
}

export interface GenericInsightSource extends Record<string, unknown> {
  GenericInsightID: number;
  parentGenericInsightID: Nullable<number>;
  customerID: number;
  dairyCowID: number;
  farmID: number;
  herdGroupID: HerdGroupEntity["id"];
  insightID: string;
  insightTypeID: InsightTypeEntity["id"];
  dairyCow?: DairyCowSource | DairyCowEntity["id"];
  insightType?: InsightTypeSource | InsightTypeEntity["id"];
  nodeID: Nullable<string>;
  createdEpoch: number;
  startEpoch: number;
  modificationEpoch: number;
  endEpoch: number;
  insightTitle: string;
  insightDescription: string;
  seenByUserIds: Nullable<string>; // comma separated
  visibleToUserID: Nullable<UserEntity["id"]>;
  insightState: InsightState;
  insightScope: InsightScope;
  insightContext: Nullable<string>; // JSON string
  insightMetadata: Nullable<string>; // JSON string
  insightResolution: Nullable<string>; // JSON string
  insightValidations: Array<InsightValidationSource | InsightValidationEntity["id"]>;
  insightPriority: number;
  dataQuality: number;
  modelSources: string;
  notificationSent: boolean;
  workflowState: InsightWorkflowState;
  workflowStateLastModifiedDate: Nullable<string>;
}

export interface InsightTypeEntity {
  id: number;
  typeName: string;
  title: string;
  createdEpoch: number;
  modifiedEpoch: number;
  insightCategory: InsightCategory;
  insightCategorySpecific: string;
  insightScope: InsightScope;
  lowerBoundInterval: number;
  slidingWindowInterval: number;
  upperBoundInterval: number;
}

export interface InsightValidationEntity {
  id: Nullable<number>; // allow a null when it is not yet created
  genericInsightID: GenericInsightEntity["id"];
  validation: InsightValidation;
  validatedBy: Nullable<UserEntity["id"]>;
}

/**
 * @todo expand if needed
 */
export interface InsightContext {
  previousCalvingEpoch: number;
}

export interface GenericInsightEntity extends Record<string, unknown> {
  id: number;
  parent: Nullable<number>;
  resolution: Array<InsightResolutionEntity["id"]>; // @todo make a type for resolutions
  context: InsightContext;
  metadata: {
    developerTitle: string;
    developerDescription: string;
  } & Record<string, unknown>; // @todo make a type for metadata
  dairyCow: number;
  farm: FarmEntity["id"];
  herdGroup: HerdGroupEntity["id"];
  insightType: InsightTypeEntity["typeName"];
  validations: Array<InsightValidationEntity["id"]>;
  node: Nullable<string>;
  createdEpoch: number;
  startEpoch: number;
  endEpoch: number;
  seenBy: Array<UserEntity["id"]>;
  workflowState: InsightWorkflowState;
  workflowStateLastModifiedDate: Nullable<string>;
}

export interface KPIInsightSettings {
  isEnabled: boolean;
  sensitivity: Nullable<number>;
  range: Nullable<{
    minValue: Nullable<number>;
    maxValue: Nullable<number>;
  }>;
  unitSystem: UnitSystemParam;
}

export interface KPIInsightServerValues {
  farmID: FarmEntity["id"];
  dataDescriptorID: DataDescriptorEntity["id"];
  group: Nullable<string>;
  settings: KPIInsightSettings;
}

export interface KPIInsightSettingsValues {
  group: Nullable<string>;
  settings: KPIInsightSettings;
}

export type KPIInsightSettingsList = Array<KPIInsightSettingsValues>;

export type KPIInsightSettingsState = Record<
  FarmEntity["id"],
  Record<DataDescriptorEntity["id"], KPIInsightSettingsList>
>;
export interface NotificationMappingSource {
  channels: Partial<Record<NotificationType, boolean>>;
  category: string;
  _id: string;
}
export interface GroupMappingSource extends NotificationMappingSource {
  groupName: string;
}
export interface NotificationMappingEntity {
  id: string;
  channels: Partial<Record<NotificationType, boolean>>;
  category: string;
}

export type NotificationMappingsState = Record<NotificationMappingEntity["id"], NotificationMappingEntity>;

export type MappingGroupEntity = {
  groupName: string;
  mappings: Array<NotificationMappingEntity["id"]>;
};

export type MappingGroups = Record<MappingGroupEntity["groupName"], MappingGroupEntity>;
export interface InsightSettingsState {
  mappings: NotificationMappingsState;
  mappingGroups: MappingGroups;
  kpiInsights: KPIInsightSettingsState;
}

export interface InsightsState {
  entities: Record<GenericInsightEntity["id"], GenericInsightEntity>;
  insightTypes: Record<InsightTypeEntity["typeName"], InsightTypeEntity>;
  insightValidations: Record<NonNullable<InsightValidationEntity["id"]>, InsightValidationEntity>;
  insightResolutions: Record<InsightResolutionEntity["id"], InsightResolutionEntity>;
  lists: Record<string, Array<GenericInsightEntity["id"]>>;
}

export type KPIInsightFormValues = {
  farmID: FarmEntity["id"];
  group: Nullable<string>;
  dataDescriptorID: Nullable<DataDescriptorEntity["id"]>;
  /**
   * settings
   */
  isEnabled: boolean;
  sensitivity: Nullable<number>;
  range: Nullable<[Nullable<number>, Nullable<number>]>;
  unitSystem: UnitSystem;
};

export enum InsightResolutionType {
  preset = "preset",
  custom = "custom"
}

export interface InsightResolutionEntity {
  version: 2;
  id: string;
  title: string;
  source: InsightResolutionType;
  insightCategory?: {
    any?: Array<string>;
    none?: Array<string>;
  };
  insightType?: {
    any?: Array<InsightTypeSource["typeName"]>;
    none?: Array<InsightTypeSource["typeName"]>;
  };
  validation?: InsightValidation;
  flags: {
    hidden?: boolean;
  };
}

export type CleanResolution = Pick<InsightResolutionEntity, "id" | "version" | "source"> & {
  hidden: InsightResolutionEntity["flags"]["hidden"];
};

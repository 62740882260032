import * as _ from "lodash";

import { FarmEntity, Insights, InsightType } from "@ctra/api";
import { MONTH, YEAR, isProduction } from "@ctra/utils";

import { useCurrentUser } from "@auth";
import { useFarm } from "@farms";

type LatestInsights = {
  insightListQuery: Record<string, unknown> | null;
  overviewQuery: Record<string, unknown> | null;
  insightListName: string;
};

/**
 * List query and list name for latest insights
 */
export const useLatestInsights = (farmID?: FarmEntity["id"]): LatestInsights => {
  const { farm } = useFarm();

  const {
    user: { id: userID }
  } = useCurrentUser();

  /**
   * Query preset for KPI insights
   * @type {{and: ({visibleToUserID: string | undefined} | PlainObject)[]}}
   */
  const kpiInsightPreset = {
    or: _.compact([
      isProduction()
        ? null
        : {
            insightMetadata: { contains: `\"stage\": 4` },
            ...Insights.queries.ofType([InsightType.vulcanV2])
          },
      {
        visibleToUserID: userID,
        ...Insights.queries.ofType(InsightType.farmKPI)
      }
    ])
  };

  /**
   * Stocking density insights
   * @type {PlainObject}
   */
  const penDensityPreset = Insights.queries.ofTypeID(83);

  /**
   * Onboarding insights
   * @type {PlainObject}
   */
  const onboardingInsightPreset = Insights.queries.ofType(InsightType.onboarding);

  /**
   * Make a query for the insight list widget
   * @type {{filter: {or: ({[p: string]: any, or: ({[p: string]: any, insightMetadata: {contains: string}} | {[p: string]: any, visibleToUserID: string | undefined})[]} | PlainObject)[], insightState: {in: Array<string>}}, expand: Array<string>, orderBy: string} | null}
   * @todo don't add farm info here, that goes one level deeper
   */
  const insightListQuery = userID
    ? {
        orderBy: "endEpoch desc",
        expand: Insights.presets.expandDefaults(),
        filter: {
          insightState: {
            in: Insights.presets.insightStates()
          },
          or: [
            {
              ...Insights.queries.from(5, MONTH),
              ...kpiInsightPreset
            },
            penDensityPreset,
            onboardingInsightPreset
          ]
        }
      }
    : null;

  /**
   * Make a query for the overview widget
   * @type {{filter: {or: ({[p: string]: any, or: ({[p: string]: any, insightMetadata: {contains: string}} | {[p: string]: any, visibleToUserID: string | undefined})[]} | PlainObject)[], insightState: {in: Array<string>}}, expand: Array<string>, orderBy: string} | null}
   * @todo don't add farm info here, that goes one level deeper
   */
  const overviewQuery = userID
    ? {
        orderBy: "endEpoch desc",
        expand: Insights.presets.expandDefaults(),
        filter: {
          insightState: {
            in: Insights.presets.insightStates()
          },
          or: [
            {
              ...Insights.queries.from(1, MONTH),
              ...kpiInsightPreset
            },
            penDensityPreset
          ]
        }
      }
    : null;

  /**
   * Set an insight list name, append the farm name or id if available.
   */
  const farmId = farm ? _.camelCase(farm.name as string) || farm.id : null;
  const insightListName = _.join(_.compact(["latestInsights", farmId]), "-");

  return { insightListQuery, insightListName, overviewQuery };
};

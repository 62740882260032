import { ComponentProps, FC } from "react";
import * as Sentry from "@sentry/react";
import { Datum } from "@ant-design/charts";
import * as _ from "lodash";
import { useDeepCompareMemo } from "use-deep-compare";

import { Col, Row, Skeleton } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { classname, Units, UnitSystem } from "@ctra/utils";
import { Scatter } from "@ctra/charts";

import { useLocalization } from "@base";

import { useInsight } from "../../../insight";
import { StockingDensityFooter } from "../../../insight-extensions";
import { StockingDensityInsight } from "../../../processing/StockingDensityInsight";
import { InsightValidation } from "../../../insight-validation";
import baseStyles from "../InsightBody/InsightBody.module.less";

const {
  insights: {
    extensions: { stockingDensity }
  }
} = Content;

/**
 * Body for the stocking density insight in 2 variants
 * @param {"card" | "dialog"} variant
 * @returns {JSX.Element}
 * @constructor
 */
export const StockingDensityInsightBody: FC = () => {
  const { t } = useTranslation();
  const { unitSystem } = useLocalization();

  const {
    getMetadata,
    meta: { isLoading }
  } = useInsight<StockingDensityInsight>();

  const {
    drop,
    datapointsX,
    datapointsY,
    metricName,
    cowsLow,
    cowsHigh,
    curveSlope,
    curveMidpoint,
    curveHigh
  } = getMetadata();

  /**
   * Chart configuration
   * @type {{pointStyle: {fill: string, stroke: string, lineWidth: number}, yAxis: {title: {text: string}}, xField: string, xAxis: {title: {text: string}}, data: any, size: number, yField: string, tooltip: {formatter: (datum: Datum) => {name: any, value: any}}, regressionLine: {type: string}, autoFit: boolean}}
   */
  const chartConfig: ComponentProps<typeof Scatter> = useDeepCompareMemo(
    () => ({
      data: _.reduce<[number | undefined, number | undefined], Array<{ x: number; y: number | undefined }>>(
        _.zip(datapointsX, datapointsY),
        (res, [x, y]) => {
          if (_.isNumber(x) && x >= cowsLow && x <= cowsHigh) {
            /**
             * Tell whether to convert the metric to imperial
             * @type {boolean}
             */
            const shallConvert: boolean =
              metricName === "averageYield" && unitSystem === UnitSystem.imperial && _.isNumber(y);

            res.push({
              x,
              y: shallConvert
                ? Units.convertToUnitSystem(
                    y as number,
                    {
                      [UnitSystem.imperial]: "lb",
                      [UnitSystem.metric]: "kg"
                    },
                    {
                      from: UnitSystem.metric,
                      to: UnitSystem.imperial
                    }
                  )
                : y
            });
          }

          return res;
        },
        []
      ),
      animation: false,
      xField: "x",
      yField: "y",
      size: 3,
      pointStyle: {
        stroke: "#777777",
        lineWidth: 1,
        fill: "#5B8FF9"
      },
      autoFit: true,
      regressionLine:
        _.isNumber(drop) && drop > 0
          ? {
              // algorithm: (data) => {
              //   const sorted = _.sortBy(data, "x");
              //   const first = _.first(sorted);
              //   const last = _.last(sorted);
              //
              //   return _.map([first, last], ({ x }) => {
              //     /**
              //      * Tell whether to convert the metric to imperial
              //      * @type {boolean}
              //      */
              //     const shallConvert: boolean =
              //       metricName === "averageYield" && unitSystem === UnitSystem.imperial;
              //
              //     /**
              //      * Metric value
              //      * @type {number}
              //      */
              //     const metricValue: number = curveHigh / (1 + Math.exp(curveSlope * (x - curveMidpoint)));
              //
              //     /**
              //      * Final value
              //      * @type {number}
              //      */
              //     const finalValue: number = shallConvert
              //       ? Units.convertToUnitSystem(metricValue, {
              //           [UnitSystem.imperial]: "lb",
              //           [UnitSystem.metric]: "kg"
              //         })
              //       : metricValue;
              //
              //     return [x, finalValue];
              //   });
              // },
              style: {
                lineWidth: 3
              },
              type: "linear" // linear, exp, loess, log, poly, pow, quad
            }
          : void 0,
      xAxis: {
        title: {
          text: t<string>(stockingDensity.chart.axis.x)
        }
      },
      yAxis: {
        title: {
          text: t<string>(stockingDensity.chart.axis.y, { metricName })
        },
        label: {
          formatter: (value: string) => {
            return _.round(parseFloat(value), 2);
          }
        }
      },
      tooltip: {
        formatter: (datum: Datum) => {
          return { name: datum.x, value: datum.y };
        }
      }
    }),
    [datapointsX, datapointsY, unitSystem, cowsLow, cowsHigh, metricName]
  );

  return (
    <Skeleton active loading={isLoading}>
      <Row className={classname(baseStyles.Row, baseStyles.Padded)}>
        <Col flex={1}>
          <InsightValidation />
        </Col>
      </Row>
      <Sentry.ErrorBoundary fallback={<>{null}</>}>
        <Row className={classname(baseStyles.Row, baseStyles.PaddedChart)}>
          <Col flex={1}>
            <Scatter {...chartConfig} />
          </Col>
        </Row>
      </Sentry.ErrorBoundary>
      <Row className={classname(baseStyles.Gray, baseStyles.Row, baseStyles.LastRow, baseStyles.Padded)}>
        <Col>
          <StockingDensityFooter />
        </Col>
      </Row>
    </Skeleton>
  );
};
